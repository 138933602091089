
import React, { Component } from "react";
import { Link } from "react-router-dom";


export class pageeror extends Component {
  render() {
    return (
      
      <div
        className="
          flex
          items-center
          justify-center
          w-screen
          h-screen
          bg-black
          to-blue-400
        "
      >
            
      {/* <LoadingBar
        color='rgb(136, 0, 255)'
        progress={this.progress}
        onLoaderFinished={() => setProgress(0)}
      /> */}
        <div className="px-40 py-20 bg-red-1000 rounded-md shadow-xl">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-blue-600 text-9xl">404</h1>

            <h6 className="mb-2 text-2xl font-bold text-center text-white md:text-3xl">
              <span className="text-red-500">Oops!</span> Page not found
            </h6>

            <p className="mb-8 text-center text-gray-500 md:text-lg">
              The page you’re looking for doesn’t exist.
            </p>
    
              <Link
                to="/" 
                className=" flex mx-auto mt-16 items text-white bg-red-500 border-0 py-5  px-8 focus:outline-none hover:bg-red-600 rounded text-lg"
              >
                Go to Home
              </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default pageeror;

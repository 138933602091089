import "./App.css";
import Aboutme from "./components/portfolio/Aboutme";
import Navbar from "./components/Headers/Navbar";
import Myachivements from "./components/portfolio/Myachivements";
import { Routes, Route } from "react-router-dom";
import FooTer from "./components/Footers/FooTer";
import MyHobbies from "./components/portfolio/MyHobbies";
import Pgerror from "./components/Error/Pageeror";
import Contactme from "./components/portfolio/Contactme";
import Projects from "./components/Projects/ProjectsMain";
import Scrolltotop from "./components/portfolio/scrolltotop";
import LoadingBar from "react-top-loading-bar";
import { useState} from  'react';
import Commandline from "./components/Projects/command_line/Command_line";
import Breadcrum from "./components/Breadcrums/Breadcrum";
import Ddossim from "./components/Projects/ddosim/Ddossim"
import Resume from "./components/portfolio/Resume";
import Commandline_docs from "./components/Projects/Docs/Commandline_docs";
import Sidebar from "./components/Projects/Docs/Items/Sidebar";
const App = () => {
  const pageSize = 5;
  const [progress, setProgress] = useState(0)

  return (
    <div>
      <LoadingBar
      color='rgb(136, 0, 255)'
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
    />
      <Navbar name="M.Sami-Furqan" link="/aboutme" />
      {/* <Breadcrum name1="" url1="" name2=""/> */}
      <Scrolltotop>
      <Routes>
        <Route 
          
          pageSize={pageSize}
          path="/"
          element={
            <>
              <Aboutme />              
            </>
          }
          />
        <Route
          pageSize={pageSize}
          path="/aboutme"
          element={
            <>
              <Aboutme />
              <MyHobbies/>             
            </>
          }
          />
        <Route
          pageSize={pageSize}
          path="/myachivements"
          element={
            <>
              <Breadcrum name1="My Achivements" name2="" url1="/myachivements"/>
              <Myachivements />             
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="*"
          element={
            <>
             <Pgerror />  
              
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/contactme"
          element={
            <>
            <Contactme/>
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/resume"
          element={
            <>
            <Resume/>
            
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/projects"
          element={
            <>
            <Projects/>
            
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/commandline"
          element={
            <>
            <Breadcrum name1="Projects" name2="CommandLine" url1="/projects"/>
            <Commandline/>
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/ddossimulator"
          element={
            <>
            <Breadcrum name1="Projects" name2="DDoS/DoS Attack Simulator" url1="/projects"/>
            <Ddossim/>
            </>
          }
        />
        <Route
          pageSize={pageSize}
          path="/commandline-docs"
          element={
            <>
            <Commandline_docs/>
           </>
          }
        />
      </Routes>
      </Scrolltotop>
      <FooTer/>
    </div>
  );
};

export default App;

import React from "react";
import imagesami from "../Images/Image.jpeg";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import TypeWriterEffect from "react-typewriter-effect";
export default function Aboutme() {
  const [progress, setProgress] = useState(0);
  return (
    <>
      <div>
        <LoadingBar
          color="rgb(37 99 235)"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <section
          className="text-gray-400 bg-black-900 body-font font-Syne font-bold"
          id=""
        >
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0 mb-10">
              <img
                className="object-cover object-center rounded-full outline outline-offset-2 outline-blue-600 shadow-lg shadow-blue-600"
                alt="hero"
                src={imagesami}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font font-Syne sm:text-4xl text-3xl mb-4 font-bold text-white">
                Muhammad Sami Furqan
                <br className="hidden lg:inline-block" />
              </h1>
              <div className="flex justify-center items-center font-thin">
                <h1 className="text-white text-2xl">I am &nbsp;</h1>
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: "Syne",
                    color: "#1a56db",
                    fontWeight: 500,
                    fontSize: "1.5em",
                  }}
                  className="text-blue-700"
                  startDelay={500}
                  cursorColor="#3F3D56"
                  text="I am "
                  multiText={[
                    "Developer",
                    "Coder",
                    "Designer",
                    "Software Developer.",
                  ]}
                  multiTextDelay={700}
                  typeSpeed={70}
                />
              </div>
              <p className="mb-8 leading-relaxed fint">
                As a student, I am pursuing expertise in Full-Stack Development.
                For the time being, I work as a Front-End Developer with
                experience in Python, C#, HTML, CSS, and Javascript. I'm also
                actively learning frameworks like NextJS to boost my front-end
                productivity.
              </p>
              <div className="flex justify-center ">
                <Link to="/aboutme">
                  <button
                    className="inline-flex text-white bg-blue-600 outline outline-offset-2 border-0 py-2 px-6 focus:outline-none hover:bg-blue-700 rounded text-lg"
                    onClick={() => setProgress(100)}
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

import React from "react";
import propTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import LoadingBar from "react-top-loading-bar";
// import "./Css/NavbarCss.css"

export default function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0)
  return (
    <>
     <LoadingBar
        color='rgb(37 99 235)'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
   <div className="navbar  py-2	 pl-2 pr-2 relative font-Syne mt-1 font-bold text-xl whitespace-normal sm:whitespace-normal md:whitespace-nowrap lg:whitespace-nowrap">
			<nav className=" text-gray-400  bg-zinc-900 font-bold border hover:shadow hover:shadow-blue-600 hover:backdrop-blur-sm  border-zinc-800 rounded-xl">
				<div className="w-full">
					<div className="flex items-center h-20 w-full">
						<div className="flex items-center  mx-20  justify-center sm:justify-center md:justify-between lg:justify-between w-full ">
							<div className="flex justify-center items-center flex-shrink-0 mr-12 ">
								<Link to="/"  onClick={() => setProgress(100)}><h1 className=" font-bold text-md cursor-pointer">
									Muhammad <span className="text-blue-500 " > Sami Furqan</span>
								</h1></Link>
							</div>
							<div className="hidden md:block font-bold">
								<div className="ml-10 flex text-3xl items-baseline space-x-4" onClick={() => setProgress(100)} >
									<Link
										activeclass="Home"
										to="/"
										offset={50}
										duration={500}
										className="cursor-pointer hover:bg-blue-600 text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										Home
									</Link>
									<Link
										activeclass="about"
										to="/aboutme"
										offset={50}
										duration={500}
										className="cursor-pointer hover:bg-blue-600 text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										About
									</Link>
									<Link
										activeclass="myachivements"
										to="/myachivements"
										
										offset={50}
										duration={500}
										className="cursor-pointer hover:bg-blue-600 text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										My Achivements
									</Link>

									<Link
										activeclass="Services"
										to="/projects"
										
										offset={50}
										duration={500}
										className="cursor-pointer hover:bg-blue-600 text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										Projects
									</Link>
									<Link
									activeclass="Contact"
									to="/contactme"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
								>
									Contact
								</Link>
									<Link
										activeclass="Resume"
										to="/resume"
										
										offset={50}
										duration={500}
										className="cursor-pointer bg-blue-600 text-white px-4 py-4 rounded-xl text-sm font-medium hover:bg-blue-700"
									>
										My Resume
									</Link>
								</div>
							</div>
						</div>
						<div className="mr-12 flex md:hidden font-bold  ">
							<button
								onClick={() => setIsOpen(!isOpen)}
								type="button"
								className="bg-blue-600 inline-flex items-center justify-center p-2 mr-0 sm:mr-6 lg:mr-0 md:mr-0 rounded-md text-white  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
								aria-controls="mobile-menu"
								aria-expanded="false"
							>
								<span className="sr-only">Open main menu</span>
								{!isOpen ? (
									<svg
										className="block h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M4 6h16M4 12h16M4 18h16"
										/>
									</svg>
								) : (
									<svg
										className="block h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>

				<Transition
					show={isOpen}
					enter="transition ease-out duration-100 transform"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-75 transform"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					{(ref) => (
						<div className="md:hidden" id="mobile-menu"  onClick={() => setProgress(100)}>
							<div
								ref={ref}
								className="bg-zinc-900 px-4 pt-2 pb-3  space-y-1 sm:px-3 rounded-2xl"
							>
								<Link
									to="/"
									activeclass="home"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									Home
								</Link>
								<Link
									to="/aboutme"
									activeclass="about"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									About
								</Link>

								<Link
									to="/myachivements"
									activeclass="work"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									My Achivements
								</Link>
								<Link
									to="/projects"
									activeclass="services"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									Projects
								</Link>

								<Link
									to="/contactme"
									activeclass="work"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									Contact
								</Link>
								<Link
									to="/resume"
									activeclass="resume"
									
									offset={50}
									duration={500}
									className="cursor-pointer hover:bg-blue-600 text-white hover:text-black block px-3 py-2 rounded-md text-base font-medium"
								>
									Resume
								</Link>
							</div>
						</div>
					)}
				</Transition>
			</nav>
		</div>
    </>
    );
  
}

Navbar.propTypes = {
  name: propTypes.string.isRequired,
  link: propTypes.string.isRequired,
};
Navbar.defaultProps = {
  name: "Sanu",
  link: "/about",
  home: "/",
};

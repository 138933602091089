import React, { Component } from "react";
import { Link } from "react-router-dom";
import certifacateRwvcpc from "../Images/Certificate.jpg";
import Devopscertificate from "../Images/Devops.jpg";

export class Myachivements extends Component {
  static propTypes = {};

  render() {
    return (
      <>
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-center text-white font-Syne">
          My Achivements
        </h1>
        <section class="bg-black text-gray-100">
          <div class="container max-w-5xl px-4 py-12 mx-auto">
            <div class="grid gap-4 mx-4 sm:grid-cols-12">
              <div class="col-span-12 sm:col-span-3">
                <div class="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-blue-700">
                  <h3 class="text-3xl font-semibold">Timeline</h3>
                  <span class="text-sm font-bold tracking-wider uppercase text-gray-400">
                    My journey as Developer
                  </span>
                </div>
              </div>
              <div class="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                <div class="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700">
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-700">
                    <h3 class="text-xl font-semibold tracking-wide">
                      My First Ever Website only using Html
                    </h3>
                    <time class="text-xs tracking-wide uppercase text-gray-400">
                      JUL 2020
                    </time>
                    <p class="mt-3">
                      I created my first website with plain HTML and no CSS.
                    </p>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-700">
                    <h3 class="text-xl font-semibold tracking-wide">
                      Started learing CSS and Javascript
                    </h3>
                    <time class="text-xs tracking-wide uppercase text-gray-400">
                      Jul 2020
                    </time>
                    <p class="mt-3">
                      I got to know that how to add styles to my website.
                    </p>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-700">
                    <h3 class="text-xl font-semibold tracking-wide">
                      Python and Projects
                    </h3>
                    <time class="text-xs tracking-wide uppercase text-gray-400">
                      Jan 2021
                    </time>
                    <p class="mt-3">
                      I learned Python and created many Projects. Some are
                      displayed&nbsp;
                      <Link className="text-blue-500" to="/projects">
                         Here
                      </Link>
                    </p>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-700">
                    <h3 class="text-xl font-semibold tracking-wide">C# </h3>
                    <time class="text-xs tracking-wide uppercase text-gray-400">
                      Aug 2022
                    </time>
                    <p class="mt-3">
                      C# was my first language which enabled me to learn about
                      GUI more deeply.
                    </p>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-700">
                    <h3 class="text-xl font-semibold tracking-wide">
                      React and more
                    </h3>
                    <time class="text-xs tracking-wide uppercase text-gray-400">
                      Sept 2022
                    </time>
                    <p class="mt-3">
                      Web Development using React.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-center text-white font-Syne mt-6">
          Certifications
        </h1>
        <section className="text-gray-400 bg-black body-font font-Syne font-bold mt-12 mb-12">
          <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                <img
                  className="rounded-lg shadow-lg shadow-purple-600"
                  src={certifacateRwvcpc}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-purple-600 uppercase">
                  RWVCPC - Certificate
                </p>
                <h3 className="mt-2 text-2xl sm:text-left text-white font-inter md:text-4xl">
                  Remote Work and Virtual Collaboration
                </h3>
                <p className="mt-5 text-lg text-white text md:text-left">
                  The Remote Work and Virtual Collaboration Certificate (RWVCPC)
                  is appropriate for all those interested in acquiring tools and
                  techniques to prepare for the growing need remote or remote
                  work. On Febuary 12 2022 , I got a certificate for passing an
                  online exam of Remote Work and Virtual Collaboration. Which I
                  was preparing since 5th Jan 2022
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                <img
                  className="rounded-lg shadow-xl shadow-cyan-500 "
                  src={Devopscertificate}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-cyan-600 uppercase">
                  Devops Starter Certfied
                </p>
                <h3 className="mt-2 text-2xl sm:text-left text-white md:text-4xl">
                  Developer Operations Beginner
                </h3>
                <p className="mt-5 text-lg text-white  text md:text-left">
                  On Feburary 2022, i Got an Certificate from Great-Learing this
                  Certificate was provided to me for completion on Introduction
                  To DevOps
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Myachivements;

import React from 'react';
import samiimg from '../Images/Image.jpeg'
import { Link } from 'react-router-dom';
export default function FooTer() {
  return (
    <>
      <div>
<footer className="text-gray-400 bg-zinc-900 hover:shadow-blue-600 hover:shadow rounded-2xl m-2 body-font font-Syne">
  <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col rounded-xl ">
    <Link to="/"className="flex title-font font-medium items-center md:justify-start justify-center text-white">
        <img src={samiimg} alt="" className='w-14 h-14 rounded-full'/>
      <span className="ml-3 text-xl">Muhammad Sami Furqan</span>
    </Link>
    <p className="text-sm text-gray-400 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-800 sm:py-2 sm:mt-0 mt-4">© 2023 codewithsami —
      <a href="https://twitter.com/Sami87305624" className="text-gray-500 ml-1" target="_blank" rel="noopener noreferrer">@Sami</a>
    </p>
    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a className="ml-3 text-gray-400 hover:text-blue-700" href='https://twitter.com/Sami87305624'  target="_blank" rel="noreferrer">
        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
          <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"/>
        </svg>
      </a>
      <a className="ml-3 text-gray-400 hover:text-blue-700"  href='https://www.instagram.com/code_with_sami/' target="_blank" rel="noreferrer">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"/>
        </svg>
      </a>
    </span>
  </div>
</footer>
        </div>
</>
  )
}

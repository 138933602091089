import React, { Component } from "react";
import DDospic from "../Images/DDoS_ico.jpg";
import Dos from "../Images/DoS.png";
// import Scripts from "../Images/"
import customizable from "../Images/Customizable.png";
export class Command_line extends Component {
  render() {
    return (
      <>
        <h1 className="font-Syne text-white text-4xl font-bold text-center">
          {" "}
          Project - DDoS/DoS Attack Simulator
        </h1>

        <section className="font-Syne mt-4 p-2 ">
          <section className="text-gray-400 bg-zinc-800 body-font font-syne  rounded-xl border border-zinc-700">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
              <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0 mb-10">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src={DDospic}
                />
              </div>
              <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                <h1 className="font-Syne sm:text-4xl text-3xl mb-4 font-medium text-white">
                  DDoS/DoS Attack Simulator
                  <br className="hidden lg:inline-block" />
                </h1>
                <p className="mb-8 leading-relaxed">
                  DDoS Simulator is a powerful python-based software that is
                  used for attacking servers, hosts, websites using traffic. It
                  disrupts the normal traffic of a targeted server, service, or
                  network by overwhelming the target or its surrounding
                  infrastructure with a flood of Internet traffic. It sends a
                  lot of traffic on the server if the server has no protection
                  against it then it can make the servers extremely slow.
                </p>
                <div className="flex flex-wrap space-y-4 justify-center">
                  <a
                    href="https://sourceforge.net/projects/ddos-simulator/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="outline outline-offset-2 mt-4 inline-flex text-white bg-blue-600 border-0 py-2 px-6 focus:outline-none hover:bg-blue-700 rounded text-lg">
                      Download
                    </button>
                  </a>
                  <a href="!#" rel="noreferrer">
                    {" "}
                    <button className="outline outline-offset-2 ml-4 inline-flex whitespace-nowrap text-white bg-green-700 border-0 py-2 px-6 focus:outline-none hover:bg-green-800 hover:text-white rounded text-lg transition ease-in" disabled>
                      Read Documentation
                    </button>
                  </a>
                  <a
                    href="https://github.com/mynameissami/DDoS-Simulator"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <button className="outline outline-offset-2 ml-4 inline-flex text-gray-400 bg-zinc-900 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                      GitHub
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>

        <h1 className="text-center font-inter text-white  font-bold text-2xl -py-10 mt-4">
          {" "}
          Project - DDoS/DoS Attack Simulator
        </h1>
        <section className="py-20 bg-black font-inter">
          <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                <img
                  className="rounded-lg shadow-lg shadow-blue-600"
                  src={Dos}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                  Begginer Friendly
                </p>
                <h3 className="mt-2 text-2xl sm:text-left text-white font-inter md:text-4xl">
                  Easy To Understand
                </h3>
                <p className="mt-5 text-lg text-white text md:text-left">
                  DDoS/DoS Attack Simulator is easy to Understand for beginners.
                  It is the best software for the people who wants to learn
                  about DDoS and Dos Attacks.
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                <img
                  className="rounded-lg shadow-xl shadow-pink-500 "
                  src={Dos}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-pink-600 uppercase">
                  Vast Funcnctions
                </p>
                <h3 className="mt-2 text-2xl sm:text-left text-white md:text-4xl">
                  Comes with many options
                </h3>
                <p className="mt-5 text-lg text-white  text md:text-left">
                  DDoS/DoS Attack Simulator comes with many options like get ip
                  from host , ip info and so on. Its quiet handy when when you
                  want to test your website , services in your Lab Enviroment.
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                <img
                  className="rounded-lg shadow-xl shadow-purple-600"
                  src={customizable}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-purple-400 uppercase">
                  Make Your Workflow Optimal
                </p>
                <h3 className="mt-2 text-2xl sm:text-left text-white md:text-4xl">
                  Customizability
                </h3>
                <p className="mt-5 text-lg  text-white text md:text-left">
                  This software is fully Customizable you can customize every
                  thing according to your needs.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="text-gray-400 bg-black body-font">
          <h1 className="font-Syne text-white text-4xl font-bold text-center mt-10">
            {" "}
            DDoS/DoS Attack Simulator Functionalities
          </h1>
          <section className="text-gray-400 bg-black body-font">
            <div className="container px-5 py-24 mx-auto">
              <div className="text-center mb-20">
                <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                  DDoS/DoS Attack Simulator Comes with many functionalities
                  which are following
                </p>
              </div>
              <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDoS Websites.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDoS IPs.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDoS Computers.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDoS Services.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDoS Networks.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    Find Hosts From IP Addresses.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                      Powerfull.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    DDOS.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    Internet Attacks.
                    </span>
                  </div>
                </div>
                <div className="p-2 sm:w-1/2 w-full">
                  <div className="bg-zinc-800 rounded flex p-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="font-Syne font-medium text-white">
                    Ip Attacks.
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://sourceforge.net/projects/ddos-simulator/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="flex mx-auto mt-16 text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded font-Syne text-lg outline outline-offset-2">
                  Download
                </button>
              </a>
            </div>
          </section>
        </section>
      </>
    );
  }
}

export default Command_line;

import { Link } from "react-router-dom";
import {te} from "tw-elements"
const Sidebar = () => {
  return (
    <>
      <>
        {/* Sidenav */}
        <nav
          id="full-screen-example"
          className="fixed top-0 left-0 z-[1035] h-screen w-60 -translate-x-full overflow-hidden bg-black text-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
          data-te-sidenav-init=""
          data-te-sidenav-content="#content"
        >
          <div className="mt-6 font-Syne text-xl">
            <div id="header-content" className="pl-4">
              <h4 className="mb-2 text-2xl font-medium leading-[1.2] text-blue-500">
                Documentation Menu
              </h4>
              <p className="mb-4">CommandLine</p>
            </div>
            <hr className="border-gray-300" />
          </div>
          <div>
            <ul
              className="relative m-0 list-none px-[0.2rem] text-white font-Syne text-lg"
              data-te-sidenav-menu-ref=""
            >
              <li className="relative">
                <a
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  href="#installation"
                  data-te-sidenav-link-ref=""
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                    </svg>
                  </span>
                  <span>Install</span>
                </a>
              </li>
              <li className="relative">
    <a
      className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
      data-te-sidenav-link-ref=""
      href="#Commands"
    >
      <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M41.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 41.4 86.6zM288 416H576c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>
</span>
<span>Commands</span>
<span
  className="absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 motion-reduce:transition-none [&>svg]:h-3 [&>svg]:w-3 [&>svg]:fill-gray-600 group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 dark:[&>svg]:fill-gray-300"
  data-te-sidenav-rotate-icon-ref=""
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    {/*! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
  </svg>
</span>
</a>
<ul
className="show !visible relative m-0 hidden list-none p-0 data-[te-collapse-show]:block"
data-te-sidenav-collapse-ref=""
>
<li className="relative">
  <a
    className="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
    data-te-sidenav-link-ref=""
    href="#basiccmd"
  >
    Basic Commands
  </a>
</li>
<li className="relative">
  <a
    className="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
    data-te-sidenav-link-ref=""
    href="#allcmd"
  >
    All Commands
  </a>
</li>
<li className="relative">
  <a
    className="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
    data-te-sidenav-link-ref=""
    href="#scriptcmd"
  >
    Script Commands
  </a>
  </li>
<li className="relative">
  <a
    className="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
    data-te-sidenav-link-ref=""
    href="#shutdowncmd"
  >
    Shutdown Commands
  </a>
</li>
</ul>
</li>
              <li className="relative">
                <a
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  href="#CommandsErrors"
                  data-te-sidenav-link-ref=""
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                  </span>
                  <span>Error Commands</span>
                </a>
              </li>
              <li className="relative">
                <a
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  href="https://sourceforge.net/projects/command-line/"
                  target="_blank"
                  data-te-sidenav-link-ref=""
                  rel="noreferrer"
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
                  </span>
                  <span>Source Forge</span>
                </a>
              </li>
            </ul>
            <hr className="border-gray-300" />
            <ul className="relative m-0 list-none px-[0.2rem]">
        
              <li className="relative">
                <a
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  href="https://mega.nz/file/OMcTmJrB#a1AhZjyz-23TSupgzhEb5pmGuTQ9Nh8kzSUleqHCi8s"
                  data-te-sidenav-link-ref=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                  </span>
                  <span>Download Docs</span>
                </a>
              </li>
              <li className="relative">
                <a
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  href="https://github.com/mynameissami/CommandLine"
                  data-te-sidenav-link-ref=""
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
                  </span>
                  <span>Github Repository</span>
                </a>
              </li>
              <li className="relative">
                <Link
                  className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                  to="/commandline"
                  data-te-sidenav-link-ref=""
                >
                  <span className="mr-4 [&>svg]:h-3.5 [&>svg]:w-3.5 [&>svg]:fill-white [&>svg]:transition [&>svg]:duration-300 [&>svg]:ease-linear group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 motion-reduce:[&>svg]:transition-none dark:[&>svg]:fill-gray-300 dark:group-hover:[&>svg]:fill-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z"/></svg>
                  </span>
                  <span>Go Back</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* Sidenav */}
        {/* Content */}
        <div className=" m-2 bg-zinc-800 rounded-2xl font-Syne ">
          <div className=" top-0 left-0 right-0 bottom-0 h-full w-full overflow-hidden bg-fixed text-center">
            <button
              id="toggler"
              className=" m-8 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
              data-te-sidenav-toggle-ref=""
              data-te-target="#full-screen-example"
              data-te-ripple-init=""
          
            >
              <span className="block [&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
            <div
              className="ml-6 break-normal max-h-screen overflow-y-auto mb-7"
              data-te-sidenav-scroll-container="#scrollContainer"
            >
              <h1 className="text-white  text-start text-4xl" id="installation">
                # Installation :-{" "}
              </h1>
              <hr className="m-2" />
              <div className="flex flex-col space-y-4 ">
                <h2 className="text-white text-start  text-2xl font-thin  ml-2">
                  - Clone the Repository :-{" "}
                </h2>
                <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                  <pre className="text-start ">
                    <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap overflow-auto break-all">
                      git clone https://github.com/mynameissami/CommandLine.git
                    </code>
                  </pre>
                </div>
                <h2 className="text-white text-start  text-2xl font-thin  ml-2">
                  - Install The Requirements :-{" "}
                </h2>
                <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                  <pre className="text-start ">
                    <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                      <span>cd CommandLine</span>
                      <br />
                      <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                        pip install -r Requirements.txt
                      </span>
                    </code>
                  </pre>
                </div>
                <div className="flex flex-col space-y-4 ">
                  <h2 className="text-white text-start  text-2xl font-thin  ml-2">
                    - Script Is Ready To Run :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap overflow-auto break-all">
                        python CommandLine.py or python3 CommandLine.py
                      </code>
                    </pre>
                  </div>
                </div>
                <h1
                  id="Commands"
                  className="text-white  text-start text-4xl mt-3 "
                >
                  # Commands :-{" "}
                </h1>
                <hr className="m-2" />
                <div className="flex flex-col space-y-4 ">
                  <h2 className="text-white text-start  text-2xl font-thin  ml-2 " id="basiccmd">
                    - Basic Commands :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span>[#] "whoami" to see about the user.</span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "create.file" to Create a new file.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "del.file" to delete a file.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "rename.file" to rename a file.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "open.file" to open files.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "update.file" to update the file.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "exit" to exit the console.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "cls" or "clear" to clear Console.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "pkg.download" to download files from internet.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "pkg.youtube" to download videos from youtube.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "pkg.youtube.mp3" to download audio from youtube.{" "}
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "showall/?" to show all commands.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "show.scripts" to show all sritps.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2"></span>
                        [#] "shutdown/?" to show shutdown commands.
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-2xl font-thin  ml-2" id="allcmd">
                    - All Commands :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span>
                          [#] "root.showip" for showing ip adrees of this
                          device.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "wifi/nby" for all nearby located wifi.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "wlan/show.pass" for getting all the Wifi
                          Passwords.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "lan.ping" for pinging websites.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "lanport/scan.ip" to scan ip addresses.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "check.urlexists" to check if url exists.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "net.browse" to open a website in a browser.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ls" to list files currently stored in this
                          directory.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ls -a" to list files currently stored in this
                          directory.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "terminal" to use Windows CMD in this Terminal.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "python.run" to run python if intalled.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "wlan.renew" to renew all adapters.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "net.speedtest" for testing network speed.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ipcon.all" for ip configuaration.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "win.ver" to show current version of windows.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "mb.gb" for converting Megabytes into Gigabytes .
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "gb.mb" for converting Gigabytes into Megabytes.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "update.commandline" for updating commandline.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "git.clone" for cloning git repositories.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "nocolor" clears the color sets to default.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "tasks.show" Show all running tasks.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "tasks.kill" Kills the specified task.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-2xl font-thin  ml-2" id="scriptcmd">
                    - Script Commands :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row    ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span>[#] "scanfix" starts windows fix kit.</span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "scanfix" starts windows fix kit.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ddos.start" Starts an ddos Attack.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "host.getip" Get ip address of host by host url.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ip.get" Gets host name from ip.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "ip.info" To get IP info.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "wlan/show.pass" Show Wifi passwords.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "lanport/scan.ip" to scan ip address.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-2xl font-thin  ml-2" id="shutdowncmd">
                    - Shutdown Commands:-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span className="">
                          [#] "shutdown.s" to shutdown this pc.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "restart.s" to restart this pc.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "remote.shutdown" to remoteley shutdown pc.
                        </span>{" "}
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          [#] "logout.s" to logout from this pc.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h1
                    id="CommandsErrors"
                    className="text-white  text-start text-4xl mt-3 "
                  >
                    # Thrown Errors by Program and their Meanings :-{" "}
                  </h1>
                  <hr className="m-2" />
                  <h2 className="text-white text-start  text-xl font-thin  ml-2">
                    - File Errors and how to avoid them :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span className="">
                          =&gt; Error &gt; File not found &gt; It means you have
                          entered wrong file name or direactory &gt; Enter
                          correct Name of file / directory.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-xl font-thin  ml-2">
                    - Host error and how to avoid them :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span className="">
                          =&gt; Error &gt; Host not Found &gt; It means the host
                          with the name you have entered is not found. &gt; Host
                          name is not correct.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-xl font-thin  ml-2">
                    - Wrong Ip address :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span className="">
                          =&gt; Error &gt; Wrong Ip address &gt; It means that
                          ip address you have entered is incorrect or not exists
                          &gt; Enter correct Ip address.
                        </span>
                        <br />
                        <span className="ml-0 sm:ml-0 md:ml-2 lg:ml-2">
                          =&gt; Error &gt; Host not found + no ip was found!
                          &gt; It means no host with the url you have entered
                          was not found & no ip address exists.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-xl font-thin  ml-2">
                    - Package Error :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all">
                        <span className="">
                          =&gt; Error &gt; Wrong Pakage! &gt; It means no file
                          found with this url or url is incorrect &gt; Enter
                          correct package URL.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                  <h2 className="text-white text-start  text-xl font-thin  ml-2">
                    - YouTube Errors :-{" "}
                  </h2>
                  <div className="border mr-12 p-4 text-justify  bg-gray-900 text-white rounded-md flex flex-row   mb-12 ">
                    <pre className="text-start ">
                      <code className="p-0 sm:p-1 md:p-2 lg:p-2  whitespace-pre-wrap  break-all mb-12">
                        <span className="">
                          =&gt; Error &gt; YouTube Video not Found &gt; It means
                          no youtube video with this url was not found &gt;
                          Check if video is private or not.
                        </span>
                        <br />
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="mt-8 mb-6">
                <a href="https://mega.nz/file/OMcTmJrB#a1AhZjyz-23TSupgzhEb5pmGuTQ9Nh8kzSUleqHCi8s" className="mt-8" target="_blank" rel="noreferrer">
                  <button className="p-4 bg-blue-700 text-white rounded-2xl outline outline-offset-2">
                    Download Full Documentation
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Content */}
      </>
    </>
  );
};

export default Sidebar;

import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoadingBar2 from "react-top-loading-bar";
import Command_line from "../Projects/Images/command_line.png";
import ddosico from "../Projects/Images/DDoS_ico.jpg";
import hackerbox from "../Projects/Images/hacking_512px.png"
export default function ProjectsMain() {
  const [progress2, setProgress2] = useState(0);
  return (
    <>
      <LoadingBar2
        color="rgb(136, 0, 255)"
        progress2={progress2}
        onLoaderFinished={() => setProgress2(0)}
      />
       <nav className="flex px-5 py-3 text-gray-700  rounded-lg bg-black  mt-5 justify-center" aria-label="Breadcrumb">
  <ol className="inline-flex items-center space-x-1 md:space-x-3 ">
    <li className="inline-flex items-center">
      <Link to="/" className="inline-flex items-center text-sm font-medium hover:text-white text-gray-400">
        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
        Home
      </Link>
    </li>
    <li>
      <div className="flex items-center">
        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
        <Link to="/projects"className="ml-1 text-sm font-medium text-gray-400  hover:text-white md:ml-2">Projects</Link>
      </div>
    </li>
    </ol>
    </nav>
      <h1 className="text-3xl p-0 text-white text-bold text-center font-Syne font-bold">
        My Projects
      </h1>
      <section className="flex flex-wrap justify-center align-middle p-24 space-x-2 font-Syne ">
        <div className="max-w-sm rounded-lg bordershadow-md bg-zinc-800 border-zinc-700 inline-block">
          <Link to="/commandline">
            <img
              className="rounded-t-lg"
              src={Command_line}
              alt=""
            />
          </Link>
          <div className="p-5 text-white">
            <Link to="/commandline">
              <h5 className="mb-2 text-2xl text-center font-bold tracking-tight  text-white">
                Command Line
              </h5>
            </Link>
            <p className="mb-3 text-center font-normal ">
            Command Line is an Open-Source Software for Pentesters and Ethical Hackers it contains many Functionalities which helps Ethical Hackers.  
            </p>
            <Link
              to="/commandline"
              className="inline-flex outline outline-offset-2 items-center py-2 px-3 mx-24 rounded border-2 mt-2 border-blue-500 bg-blue-600 text-white font-bold "
            >
              Check&nbsp;out
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
        <div className="max-w-sm rounded-lg border shadow-md bg-zinc-800 border-zinc-700 inline-block mt-2 md:mt-0 sm:mt-0 ml-2">
          <Link to="/ddossimulator">
            <img
              className="rounded-t-lg md:w-72 md:h-72 lg:w-72 lg:h-72 w-56 h-56 ml-16 lg:ml-12 md:ml-12 rounded-md mt-11"
              src={ddosico}
              alt=""
            />
          </Link>
          <div className="p-5 mt-12">
            <Link to="/ddossimulator">
              <h5 className="mb-2 text-2xl text-center font-bold tracking-tight  text-white">
                DDoS/Dos Attack Simulator
              </h5>
            </Link>
            <p className="mb-3 text-center font-normal text-white">
            DDoS Simulator is a powerful python-based software that is used for attacking servers, hosts, websites using traffic.
            </p>
            <Link
              to="/ddossimulator"
              className="inline-flex outline outline-offset-2 items-center object-contain py-2 px-3  mx-24 rounded border-2 mt-2 border-blue-500 bg-blue-600 text-white font-bold"
            >
              Check&nbsp;out
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
        <div className="max-w-sm rounded-lg border shadow-md bg-zinc-800 border-zinc-700 inline-block mt-2 md:mt-2 lg:mt-0 sm:mt-0 ml-2 ">
          <Link to="#ComingSoon">
            <img
              className="rounded-t-lg md:w-72 md:h-72 lg:w-72 lg:h-72 w-56 h-56 ml-16 lg:ml-12 md:ml-12 rounded-md mt-11"
              src={hackerbox}
              alt=""
            />
          </Link>
          <div className="p-5 mt-12">
            <Link to="#ComingSoon">
              <h5 className="mb-2 text-2xl text-center font-bold tracking-tight  text-white">
                Hacker Box
              </h5>
            </Link>
            <p className="mb-3 text-center font-normal text-white">
            A Powerful Ethical Hacking Terminal For Pentesters
            </p>
            <a
              href="#comingsoon"
              className="inline-flex outline outline-offset-2 items-center justify-center object-contain py-2 px-3  mx-24 rounded border-2 mt-7 border-blue-500 bg-blue-600 text-white font-bold"
            >
              Coming&nbsp;Soon
            </a>
          </div>
        </div>
       
      </section>
    </>
  );
}

import React, { Component } from "react";
import Resume1 from "../Images/Resume1.png";
import samipng from "../Images/Image.jpeg";
import { Link } from "react-router-dom";
export class Resume extends Component {
  render() {
    return (
      <>
      <nav className="flex px-5 py-3 text-white  rounded-lg bg-black  mt-5 justify-center" aria-label="Breadcrumb">
  <ol className="inline-flex items-center space-x-1 md:space-x-3 ">
    <li className="inline-flex items-center">
      <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-400 hover:text-white">
        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
        Home
      </Link>
    </li>
    <li>
      <div className="flex items-center">
        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
        <Link to="/resume" className="ml-1 text-sm font-medium md:ml-2 text-gray-400 hover:text-white">Resume</Link>
      </div>
    </li>
    </ol>
    </nav>
        <h1 className="font-Syne font-bold justify-center text-center text-white text-6xl">
          My Resume
        </h1>
        <section className="text-white body-font font-Syne font-bold">
          <div className="container px-5 py-24 mx-auto flex flex-col">
            <div className="lg:w-4/6 mx-auto">
              <div className="rounded-lg h-5/6 w-5/6 lg:mx-12 md:mx-12 md:sm-0 lg:sm-0 sm:ml-7 ml-7 overflow-hidden">
                <img
                  alt="content"
                  className=" justify-center object-center h-full w-full"
                  src={Resume1}
                />
              </div>
              <section className="border border-zinc-700 rounded-2xl bg-zinc-900 p-4 mt-4 mr-0 md:mr-0 sm:mr-0 lg:mr-16 align-middle justify-center">
              <div className="flex flex-col sm:flex-row mt-10">
                <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                  <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                    <img src={samipng} className="rounded-full" alt="" />
                  </div>
                  <div className="flex flex-col items-center text-center justify-center">
                    <h2 className="font-medium title-font mt-4 text-white text-lg">
                      Muhammad Sami Furqan
                    </h2>
                    <div className="w-12 h-1 bg-blue-600 rounded mt-2 mb-4"></div>
                    <p className="text-base">
                      My name is M.Sami-Furqan and i am a CS Student.I have
                      experienced with different programming languages including
                      Python,C++,C#. I also have 2 years of experience with web
                      developing.I am also familiar with the frameworks like
                      Node.js , Express.js , React etc
                    </p>
                  </div>
                </div>
                <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                  <p className="leading-relaxed text-lg mb-4">
                    Currently i am Programmer i am developing web applications
                    and Softwares from almost 2+ years.I am designing
                    GUI-Interfaces since year 2020 and designed many UI's. I am
                    also a Web Developer , I am making websites since 2020. I
                    can make fully functional websites and can upload them on
                    Domains
                  </p>
                  <Link
                    className="text-blue-600 inline-flex items-center cursor-pointer"
                    to="/aboutme"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                  <br />
                  <div className="mt-12">
                  <a href="https://drive.google.com/file/d/1uZA1ZKXXobgBFM2YdYwQV5Aip2QpE0xG/view?usp=sharing" target="_blank" rel="noreferrer"><button className=" md:px-20 sm:py-4 lg:px-20 px-2 py-2 rounded-xl bg-blue-600 hover:bg-blue-700 border-2 font-syne mx-4 ml-2 lg:ml-24 md:ml-14 bg-fixed outline outline-offset-2 whitespace-nowrap ">Download Resume</button></a>
                  </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Resume;
